import React, { useEffect, useState } from 'react'
import { BuildingPanorama, ColorStrings, UrbanImage2, city } from './utils/Utils';
import { makeStyles } from '@material-ui/core';
import axios from 'axios';
import { BackendRootStrings } from './utils/RouteStrings';
import { BlogPostsInterface } from './TypeImplements';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    '@media (min-width: 780px)': {
        width: '80%'
    },

    box1: {
        backgroundImage: `url(${city})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover"
    },
    mainDiv1: {
        '@media (min-width: 1708px)': {
            // height: "3416px !important"
        },
    },
    aboutUsDiv1: {
        '@media (min-width: 1426px)': {
            // marginTop:"-851px !important"
            height: "31% !important"
        },
    },
    aboutUsDiv1div1: {
        '@media (min-width: 1426px)': {
            // marginTop:"-851px !important"
            height: "105% !important"
        },
    },
    boardMemberDiv1: {
        '@media (min-width: 1426px)': {
            // marginTop:-851
            height: "28% !important"
        },
    },
    backImage2: {
        backgroundImage: `url("${UrbanImage2}")`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover"
    },
    backImage1: {
        backgroundImage: `url("${BuildingPanorama}")`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover"
    },
    div4: {
        '@media (max-width: 1426px)': {
            // marginTop:-851
            marginTop: "780px !important"
        },
    },
    div5: {
        '@media (max-width: 1426px)': {
            // marginTop:-851
            marginTop: "440px !important"
        },
    },




}));


const OngoingProjects = () => {

    const classes = useStyles();

    const [data, setData] = useState<BlogPostsInterface[]>([]);

    const blogPosts = [
        {
            image_url: 'https://via.placeholder.com/1200',
            title: 'Exploring the Beauty of Nature'
        },
        {
            image_url: 'https://via.placeholder.com/1200',
            title: 'The Art of Cooking: A Culinary Journey'
        },
        {
            image_url: 'https://via.placeholder.com/1200',
            title: 'The Impact of Technology on Modern Society'
        },
        {
            image_url: 'https://via.placeholder.com/1200',
            title: 'Unlocking Creativity: Finding Inspiration Everywhere'
        },
        {
            image_url: 'https://via.placeholder.com/1200',
            title: 'Discovering New Horizons: Traveling the World'
        },
        {
            image_url: 'https://via.placeholder.com/1200',
            title: 'The Power of Mindfulness: Living in the Present'
        },
        {
            image_url: 'https://via.placeholder.com/1200',
            title: 'Exploring the Wonders of Space: A Journey Beyond Earth'
        },
        {
            image_url: 'https://via.placeholder.com/1200',
            title: 'The Joy of Reading: Escaping into Fictional Worlds'
        },
        {
            image_url: 'https://via.placeholder.com/1200',
            title: 'Embracing Diversity: Celebrating Differences'
        },
        {
            image_url: 'https://via.placeholder.com/1200',
            title: 'The Pursuit of Happiness: Finding Fulfillment in Life'
        }
    ];

    useEffect(() => {
        const fetchData = async () => {
            const {data}=await axios.get(`${BackendRootStrings.apiHome}/getOngoingProjectsData`);
            setData(data);
        }
        fetchData();
    }, [])


    return (
        <div className={classes.mainDiv1} style={{ display: "flex", justifyContent: "center", height: "fit-content", }}>
            <div style={{ display: "flex", height: "100%", width: "100%", flexDirection: "column", alignItems: "center" }}>
                <div className={classes.backImage1} style={{ width: "100%", height: 400, display: "flex", justifyContent: "center", alignItems: "center" }}>
                    {/* <img src={city} style={{ width: "100%", height: "100%" }} alt="" /> */}
                    <h2>Ongoing Projects</h2>
                </div>
                <div style={{ height: "100%", width: "100%", display: "flex", flexWrap: "wrap", justifyContent: "flex-start", border: "1px solid",backgroundColor:ColorStrings.BlackColor }}>
                    {
                        data.map((obj: BlogPostsInterface, ind: number) => {
                            return (
                                <div style={{ display: "flex", flexDirection: "column", border: "5px solid", marginTop: 10, justifyContent: "flex-start", alignItems: "flex-start", marginRight: 10,height:'20%',marginBottom:10 }} key={ind}>
                                    <div><img style={{ height: 250, width: 330 }} src={`data:image/png;base64,${obj.image_url}`} alt="" /></div>
                                    <p style={{ height: 5, width: 330, color: ColorStrings.GoldenColor, fontWeight: "bold", fontSize: 20, }}>{obj.title}</p>
                                    <p style={{ height: 100, width: 330, color: "white", }}>{obj.content.substring(0,40)}</p>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default OngoingProjects