import React, { useEffect, useState } from 'react';
import { GalleryDataInterface } from './TypeImplements';
import axios from 'axios';
import { BackendRootStrings, RouteStrings } from './utils/RouteStrings';
import { BuildingPanorama, city, messageBox } from './utils/Utils';
import { makeStyles } from '@material-ui/core';
import { Image } from 'antd';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  '@media (min-width: 780px)': {
    width: '80%'
  },


  backImage2: {
    backgroundImage: `url("${BuildingPanorama}")`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover"
  },
  imageDiv:{
    '& .ant-image .ant-image-img':{
      width:"98%"
    }
  }




}));


const Gallery = () => {

  const classes = useStyles();
  const [data, setData] = useState<GalleryDataInterface[]>([]);
  useEffect(() => {
    axios.get(`${BackendRootStrings.apiHome}/fetchGalleryImage`)
      .then(({ data }) => {
        setData(data);
      })
  }, [])

  // const data = [
  //   {
  //     "name": "John Doe",
  //     "image_url": "https://source.unsplash.com/400x400/?nature"
  //   },
  //   {
  //     "name": "Jane Smith",
  //     "image_url": "https://source.unsplash.com/400x400/?nature"
  //   },
  //   {
  //     "name": "Alice Johnson",
  //     "image_url": "https://source.unsplash.com/400x400/?nature"
  //   },
  //   {
  //     "name": "Michael Brown",
  //     "image_url": "https://source.unsplash.com/400x400/?nature"
  //   },
  //   {
  //     "name": "Emily Davis",
  //     "image_url": "https://source.unsplash.com/400x400/?nature"
  //   },
  //   {
  //     "name": "David Wilson",
  //     "image_url": "https://source.unsplash.com/400x400/?nature"
  //   },
  //   {
  //     "name": "Sarah Martinez",
  //     "image_url": "https://source.unsplash.com/400x400/?nature"
  //   },
  //   {
  //     "name": "Robert Taylor",
  //     "image_url": "https://source.unsplash.com/400x400/?nature"
  //   },
  //   {
  //     "name": "Linda Anderson",
  //     "image_url": "https://source.unsplash.com/400x400/?nature"
  //   },
  //   {
  //     "name": "James Thomas",
  //     "image_url": "https://source.unsplash.com/400x400/?nature"
  //   },
  //   {
  //     "name": "Susan Garcia",
  //     "image_url": "https://source.unsplash.com/400x400/?nature"
  //   },
  //   {
  //     "name": "Matthew Hernandez",
  //     "image_url": "https://source.unsplash.com/400x400/?nature"
  //   },
  //   {
  //     "name": "Karen Young",
  //     "image_url": "https://source.unsplash.com/400x400/?nature"
  //   },
  //   {
  //     "name": "Daniel King",
  //     "image_url": "https://source.unsplash.com/400x400/?nature"
  //   },
  //   {
  //     "name": "Margaret Scott",
  //     "image_url": "https://source.unsplash.com/400x400/?nature"
  //   },
  //   {
  //     "name": "Christopher Clark",
  //     "image_url": "https://source.unsplash.com/400x400/?nature"
  //   }
  // ]


  const myData: string[] = [
    "https://images.unsplash.com/photo-1710097092298-75d5c777a9bf?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://images.unsplash.com/photo-1710097092298-75d5c777a9bf?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://images.unsplash.com/photo-1710097092298-75d5c777a9bf?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://images.unsplash.com/photo-1710097092298-75d5c777a9bf?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://images.unsplash.com/photo-1710097092298-75d5c777a9bf?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://images.unsplash.com/photo-1710097092298-75d5c777a9bf?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://images.unsplash.com/photo-1710097092298-75d5c777a9bf?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://images.unsplash.com/photo-1710097092298-75d5c777a9bf?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://images.unsplash.com/photo-1710097092298-75d5c777a9bf?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://images.unsplash.com/photo-1710097092298-75d5c777a9bf?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://images.unsplash.com/photo-1710097092298-75d5c777a9bf?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://images.unsplash.com/photo-1710097092298-75d5c777a9bf?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://images.unsplash.com/photo-1710097092298-75d5c777a9bf?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://images.unsplash.com/photo-1710097092298-75d5c777a9bf?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://images.unsplash.com/photo-1710097092298-75d5c777a9bf?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",

  ]



  return (
    <>
      <div style={{ display: "flex", minHeight: 305, height: "fit-content", justifyContent: "center", width: "100%", }}>
        <div style={{ display: "flex", flexDirection: "column", width: "100%", alignItems: "center" }}>
          <div className={classes.backImage2} style={{ width: "100%", height: 400, display: "flex", justifyContent: "center", alignItems: "center" }}>
            {/* <img src={city} style={{ width: "100%", height: "100%" }} alt="" /> */}
            <h2>Gallery</h2>
          </div>

          <div style={{ width: "100%", height: "fit-content", display: "flex", flexWrap: "wrap", marginTop: 15 }}>

            {data.map((item, index) => {
              const imageData = [{ src: `data:image/png;base64,${item.image_url}` }];
              if (index < data.length - 1) {
                imageData.push({ src: `data:image/png;base64,${data[index + 1].image_url}` });
              }
              return (
                <div className={classes.imageDiv} style={{ marginLeft: 20, marginBottom: 20 }} key={index}>
                  <Image.PreviewGroup items={imageData}>
                    <Image style={{ height: 300 }} src={`data:image/png;base64,${item.image_url}`} />
                  </Image.PreviewGroup>
                  <p style={{ textAlign: "center", fontWeight: "bold", color: "black" }}>{item.name}</p>
                </div>
              );
            })}

          </div>
        </div>

      </div>

    </>
  )
}

export default Gallery