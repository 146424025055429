import React, { useState, useEffect } from 'react';
import './CarauselCss.css'

interface CarouselProps {
  images: string[];
}

const Carousel: React.FC<CarouselProps> = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [renderKey, setRenderKey] = useState(Math.random)

  const goToPrevSlide = () => {
    const index = (currentIndex - 1 + images.length) % images.length;
    setCurrentIndex(index);
  };

  const goToNextSlide = () => {
    const index = (currentIndex + 1) % images.length;
    setCurrentIndex(index);
  };

  useEffect(() => {
    const intervalId = setInterval(goToNextSlide, 3000); // Change 3000 to adjust the interval time (in milliseconds)
    return () => clearInterval(intervalId);
  }, [currentIndex, images]);
  

  return (
    
      <img className="rts-reveal-image-one carousel-image" src={`${images[currentIndex]}`} alt={`Slide ${currentIndex}`} />
      
  );
};

export default Carousel;
