import React, { useEffect, useState } from 'react'
import Header from './Header';

import $ from 'jquery';

import '../builders website_innovate theam/css/bootstrap.min.css';
import '../builders website_innovate theam/css/fontawesome-6.css';
import '../builders website_innovate theam/css/magnific-popup.css';
import '../builders website_innovate theam/css/metismenu.css';
import '../builders website_innovate theam/css/style.css';
import '../builders website_innovate theam/css/swiper.min.css'

// import '../builders website_innovate theam/js/jquery.min.js';
// import '../builders website_innovate theam/js/waw.js';
// import '../builders website_innovate theam/js/gsap.js';
// import '../builders website_innovate theam/js/metismenu.js';

// import "../builders website_innovate theam/js/swiper.js";
// import "../builders website_innovate theam/js/counterup.js";
// import "../builders website_innovate theam/js/waypoint.js";
// import "../builders website_innovate theam/js/chroma.min.js";
import Carousel from './Carausel';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { RouteStrings } from './utils/RouteStrings';
import Gallery from './Gallery';
import About from './About';
import Contact from './Contact';
import MainHome from './MainHome';
import PropertiesMenu from './PropertiesMenu';
// import '../builders website_innovate theam/js/scrolltiger.js';
// import '../builders website_innovate theam/js/scrolltoplugin.js';

import { FaXTwitter } from "react-icons/fa6";
import Blog from './Blog';
import { Menu } from 'antd';
import type { MenuProps } from 'antd';
import OngoingProjects from './OngoingProjects';
import UpcomingProjects from './UpcomingProjects';
import BlogContent from './BlogContent';
import { ColorStrings, logo } from './utils/Utils';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    '@media (min-width: 780px)': {
        width: '80%'
    },
    footerUsefulLinks:{
        '@media (max-width: 370px)': {
            width: '85% !important'
        },
    }

}))

const items: MenuProps['items'] = [
    {
        label: 'Navigation Three - Submenu',
        key: 'SubMenu',

        children: [
            {
                type: 'group',
                label: 'Item 1',
                children: [
                    {
                        label: 'Option 1',
                        key: 'setting:1',
                    },
                    {
                        label: 'Option 2',
                        key: 'setting:2',
                    },
                ],
            },
            {
                type: 'group',
                label: 'Item 2',
                children: [
                    {
                        label: 'Option 3',
                        key: 'setting:3',
                    },
                    {
                        label: 'Option 4',
                        key: 'setting:4',
                    },
                ],
            },
        ],
    },
    {
        label: (
            <a href="https://ant.design" target="_blank" rel="noopener noreferrer">
                Navigation Four - Link
            </a>
        ),
        key: 'alipay',
    },

];



const Home: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const classes=useStyles();
    // useEffect(() => {
    //     // Use jQuery here
    //     $('#myElement').addclassName('swiper');

    //     // Ensure to return a cleanup function if needed
    //     return () => {
    //         // Clean up code if necessary
    //     };
    // }, []);


    const [current, setCurrent] = useState('SubMenu');

    const onClick: MenuProps['onClick'] = (e) => {
        console.log('click ', e);
        setCurrent(e.key);
    };

    const RenderComponent = () => {
        const blogPathPattern = new RegExp(`^${RouteStrings.Blogs}/(\\d+)$`);
        const blogMatch = location.pathname.match(blogPathPattern);
        if (blogMatch) {
            return <BlogContent />
        }
        switch (location.pathname) {
            case RouteStrings.HomePage:
                return <MainHome />
                break;
            case RouteStrings.Gallery:
                return <Gallery />
                break;
            case RouteStrings.AboutUs:
                return <About />
                break;
            case RouteStrings.ContactUs:
                return <Contact />
                break;
            case RouteStrings.Blogs:
                return <Blog />
                break;

            case RouteStrings.OngoingProjects:
                return <OngoingProjects />
                break;
            case RouteStrings.UpcomingProjects:
                return <UpcomingProjects />
                break;

            default:
                break;
        }
    }

    useEffect(() => {
        RenderComponent();
    }, [location])



    const contentStyle: React.CSSProperties = {
        height: "100%",
        color: '#fff',
        lineHeight: '160px',
        textAlign: 'center',
        background: '#364d79',
    };

    return (
        <>
            <body className="index-one">


                <div className="rts-header-style-one header--sticky" style={{ overflow: "hidden", backgroundColor: ColorStrings.GoldenColor }}>

                    <a style={{ padding: 0 }} href="/" className="logo">
                        <img style={{ height: "100%", width: "100%", marginLeft: 81, marginTop: -10 }} src={logo} alt="logo-" />
                        <span style={{ position: 'absolute', top: 75, left: 76, color: ColorStrings.BlackColor, fontWeight: 'bold' }}>{("S.S. Construction & Services".toUpperCase())}
                        </span>
                    </a>

                    <div className="nav-area-center">
                        <nav className="navigation">
                            <ul className="parent-ul">
                                <li className="has-dropdown with-megamenu">
                                    <a className="nav-link" href="/">Home</a>
                                    {/* <ul style={{gap:"0px !important"}} className="submenu">
                                        <li className="mega-menu-item">
                                            <ul className="pages">
                                                <li><a className="sdm-main title-top" href="#">Home Pages</a></li>
                                                <li><a className="sdm-main" href="index.html">Home One</a></li>
                                                <li><a className="sdm-main" href="index-two.html">Home Two</a></li>
                                                <li><a className="sdm-main" href="index-three.html">Home Three</a></li>
                                                <li><a className="sdm-main" href="index-four.html">Home Four</a></li>
                                                <li><a className="sdm-main" href="index-five.html">Home Five</a></li>
                                                <li><a className="sdm-main" href="index-six.html">Home Six</a></li>
                                                <li><a className="sdm-main" href="index-seven.html">Home Seven</a></li>
                                                <li className="last-left"><a className="last sdm-main" href="index-eight.html">Home Eight</a></li>
                                            </ul>
                                        </li>
                                        <li className="mega-menu-item">
                                            <ul className="pages">
                                                <li><a className="sdm-main title-top" href="#">One Pages</a></li>
                                                <li><a className="sdm-main" href="onepage-one.html">One Page One</a></li>
                                                <li><a className="sdm-main" href="onepage-two.html">One Page Two</a></li>
                                                <li><a className="sdm-main" href="onepage-three.html">One Page Three</a></li>
                                                <li><a className="sdm-main" href="onepage-four.html">One Page Four</a></li>
                                                <li><a className="sdm-main" href="onepage-five.html">One Page Five</a></li>
                                                <li><a className="sdm-main" href="onepage-six.html">One Page Six</a></li>
                                                <li><a className="sdm-main" href="onepage-seven.html">One Page Seven</a></li>
                                                <li><a className="sdm-main last" href="onepage-eight.html">One Page Eight</a></li>
                                            </ul>
                                        </li>
                                        <li className="mega-menu-item">
                                            <ul className="pages">
                                                <li><a className="sdm-main title-top" href="#">Showcase Pages</a></li>
                                                <li><a className="sdm-main" href="portfolio-boxes.html">Carousel Slider</a></li>
                                                <li><a className="sdm-main" href="varticle-portfolio.html">Varticle Portfolio</a></li>
                                                <li><a className="sdm-main" href="varticle-slider.html">Varticle Slider</a></li>
                                                <li><a className="sdm-main" href="varticle-slider-tab.html">Varticle Tab Slider</a></li>
                                                <li><a className="sdm-main" href="showcase-fade.html">Showcase Fade</a></li>
                                                <li><a className="sdm-main" href="modern-slider.html">Modern slider</a></li>
                                                <li className="last-right"><a className="sdm-main last" href="#">Coming Soon</a></li>
                                                <li className="last-right"><a className="sdm-main last" href="#">Coming Soon</a></li>
                                            </ul>
                                        </li>
                                    </ul> */}
                                </li>
                                <li className="has-dropdown">
                                    <Link className="nav-link" to={RouteStrings.AboutUs}>About US</Link>
                                    {/* <ul className="submenu">
                                        <li className="lim"><a className="sdm-main" href="about.html">About Us</a></li>
                                        <li className="sub-dropdown lim">
                                            <a className="sdm-main" href="javascript:void(0);">Team</a>
                                            <ul className="submenu third-lvl base">
                                                <li className="lim"><a className="mobile-menu-link" href="team-1.html">Team One</a></li>
                                                <li className="lim"><a className="mobile-menu-link" href="team-2.html">Team Two</a></li>
                                                <li className="lim"><a className="mobile-menu-link last" href="team-single.html">Team Details</a></li>
                                            </ul>
                                        </li>
                                        <li className="lim"><a className="sdm-main" href="before-after.html">Before After</a></li>
                                        <li className="lim"><a className="sdm-main" href="pricing-plane.html">Pricing Plan</a></li>
                                        <li className="lim"><a className="sdm-main" href="faq.html">Faq's</a></li>
                                        <li className="lim"><a className="sdm-main" href="contact.html">Contact Us</a></li>
                                        <li className="lim"><a className="sdm-main" href="terms-and-condition.html">Terms &amp; Condition</a></li>
                                        <li className="lim"><a className="sdm-main" href="privacy-policy.html">Privacy Policy</a></li>
                                        <li className="lim"><a className="last sdm-main" href="404.html">404 Page</a></li>
                                    </ul> */}
                                </li>
                                <li >
                                    <PropertiesMenu />
                                    {/* <a className="nav-link" href="#">Properties</a>
                                    <ul className="submenu">
                                        <li className="lim"><a className="sdm-main" href="service-1.html">Service One</a></li>
                                        <li className="lim"><a className="sdm-main" href="service-2.html">Service Two</a></li>
                                        <li className="lim"><a className="sdm-main" href="service-3.html">Service Three</a></li>
                                        <li className="lim"><a className="sdm-main" href="service-4.html">Service Four</a></li>
                                        <li className="lim"><a className="sdm-main" href="service-5.html">Service Five</a></li>
                                        <li className="lim"><a className="last sdm-main" href="service-details.html">Service Details</a></li>
                                    </ul>
                                </li>
                                <li className="has-dropdown">
                                    <a className="nav-link" href={RouteStrings.Gallery}>Gallery</a> */}
                                    {/* <ul className="submenu">
                                        <li className="lim"><a className="sdm-main" href="portfolio-grid-1.html">Portfolio Grid</a></li>
                                        <li className="lim"><a className="sdm-main" href="portfolio-grid-col-3.html">Portfolio Col-3</a></li>
                                        <li className="lim"><a className="sdm-main" href="portfolio-grid-col-4.html">Portfolio Col-4</a></li>
                                        <li className="lim"><a className="sdm-main" href="portfolio-varticle-grid.html">Portfolio Mesonary</a></li>
                                        <li className="sub-dropdown last lim">
                                            <a className="sdm-main last" href="javascript:void(0);">Project Details</a>
                                            <ul className="submenu third-lvl base">
                                                <li className="lim"><a className="mobile-menu-link" href="product-details-1.html">Project Details v.1</a></li>
                                                <li className="lim"><a className="mobile-menu-link" href="product-details-2.html">Project Details v.2</a></li>
                                                <li className="lim"><a className="mobile-menu-link" href="product-details-3.html">Project Details v.3</a></li>
                                                <li className="lim"><a className="mobile-menu-link last" href="product-details-4.html">Project Details v.4</a></li>
                                            </ul>
                                        </li>
                                    </ul> */}
                                </li>
                                <li className="has-dropdown">
                                    <a className="nav-link" href={RouteStrings.Blogs}>Blog</a>
                                    {/* <ul className="submenu">
                                        <li className="lim"><a className="sdm-main" href="blog.html">Blog</a></li>
                                        <li className="lim"><a className="sdm-main" href="blog-list.html">Blog List</a></li>
                                        <li className="lim"><a className="sdm-main" href="blog-2.html">Blog v.2</a></li>
                                        <li className="lim"><a className="sdm-main" href="blog-3.html">Blog v.3</a></li>
                                        <li className="lim"><a className="sdm-main" href="blog-4.html">Blog v.4</a></li>
                                        <li className="lim"><a className="sdm-main last" href="blog-details.html">Blog Details</a></li>
                                    </ul> */}
                                </li>
                                <li className="has-dropdown">
                                    <a className="nav-link" href={RouteStrings.Gallery}>Gallery</a>
                                    {/* <ul className="submenu">
                                        <li className="lim"><a className="sdm-main" href="blog.html">Blog</a></li>
                                        <li className="lim"><a className="sdm-main" href="blog-list.html">Blog List</a></li>
                                        <li className="lim"><a className="sdm-main" href="blog-2.html">Blog v.2</a></li>
                                        <li className="lim"><a className="sdm-main" href="blog-3.html">Blog v.3</a></li>
                                        <li className="lim"><a className="sdm-main" href="blog-4.html">Blog v.4</a></li>
                                        <li className="lim"><a className="sdm-main last" href="blog-details.html">Blog Details</a></li>
                                    </ul> */}
                                </li>
                                <li><a href={RouteStrings.ContactUs}>Contact</a></li>
                            </ul>
                        </nav>
                    </div>
                    <div className="action-area">
                        <div id="search" className="search">
                            <i className="fa-light fa-magnifying-glass"></i>
                        </div>
                        <div className="menu-icon" id="menu-btn">
                            <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0 1.34375C0 0.886719 0.351562 0.5 0.84375 0.5H14.9062C15.3633 0.5 15.75 0.886719 15.75 1.34375C15.75 1.83594 15.3633 2.1875 14.9062 2.1875H0.84375C0.351562 2.1875 0 1.83594 0 1.34375ZM0 6.96875C0 6.51172 0.351562 6.125 0.84375 6.125H10.4062C10.8633 6.125 11.25 6.51172 11.25 6.96875C11.25 7.46094 10.8633 7.8125 10.4062 7.8125H0.84375C0.351562 7.8125 0 7.46094 0 6.96875ZM5.90625 13.4375H0.84375C0.351562 13.4375 0 13.0859 0 12.5938C0 12.1367 0.351562 11.75 0.84375 11.75H5.90625C6.36328 11.75 6.75 12.1367 6.75 12.5938C6.75 13.0859 6.36328 13.4375 5.90625 13.4375Z" fill="#30373E"></path>
                            </svg>
                        </div>
                    </div>
                </div>





                <div style={{}}>

                    {RenderComponent()}
                </div>



                <div className="rts-footer-area bg-footer-image bg_image">
                    <div className="container">
                        <div className="row   ptb--100 ptb_sm--60">
                            <div className="col-lg-12">
                                <div className="footer-area-wrapper-one">
                                    <div className="single-footer-wized">
                                        <a href="#" className="logo">
                                            <img style={{ height: 150, width: 200, marginLeft: 10 }} src={logo} alt="logo" />
                                            <p style={{ color: ColorStrings.GoldenColor }}>{("S.S. Construction & Services".toUpperCase())}</p>
                                        </a>
                                        <Link to={RouteStrings.AboutUs} className="disc">
                                            <p style={{ textAlign: 'justify', color: ColorStrings.GrayWhite }}>Welcome to S.S CONSTRUCTION & SERVICES , where luxury finds its true expression in every detail.

                                                Based in Jamshedpur, Jharkhand, we've been a pioneering force in shaping Eastern India's real estate for nearly three decades. </p>
                                        </Link>
                                        {/* <form action="#">
                                            <div className="input-email">
                                                <input type="text" placeholder="Enter your email address" required={true} />
                                                <button><i className="fa-thin fa-arrow-right"></i></button>
                                            </div>
                                        </form> */}
                                    </div>
                                    <div className="single-footer-wized">
                                        <div className="menu-links-area">
                                            {/* <h6 style={{color:ColorStrings.GoldenColor}} className="title">Company</h6> */}

                                        </div>
                                    </div>
                                    <div style={{ width: 372 }} className={`${classes.footerUsefulLinks} single-footer-wized`}>
                                        <div style={{ width: "100%" }} className="menu-links-area">
                                            <h6 style={{ color: ColorStrings.GoldenColor, textAlign: "center" }} className="title">Useful Links</h6>
                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                <div>
                                                    <ul>
                                                        <li><a href={RouteStrings.AboutUs}>About Us</a></li>
                                                        <li><a href={RouteStrings.HomePage + "#our-team"}>Our team</a></li>
                                                        <li><a href={RouteStrings.OngoingProjects}>Our Services</a></li>
                                                        <li><a href={RouteStrings.UpcomingProjects}>Work Project</a></li>
                                                        <li><a href={RouteStrings.ContactUs}>Help</a></li>
                                                    </ul>
                                                </div>
                                                <div>
                                                    <ul>
                                                        <li><a href={RouteStrings.AboutUs}>Why Choose</a></li>
                                                        <li><a href={RouteStrings.Blogs}>Read Blog</a></li>
                                                        {/* <li><a href="portfolio-grid-col-4.html">Work Process</a></li> */}
                                                        {/* <li><a href="faq.html">FAQ’s</a></li> */}
                                                        <li><a href={RouteStrings.ContactUs}>Support</a></li>
                                                    </ul>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                    <div className="single-footer-wized">
                                        <div className="menu-links-area">
                                            <h6 style={{ color: ColorStrings.GoldenColor }} className="title">Contact</h6>
                                            <div className="contact-information-1">

                                                <div className="single-contact-info">
                                                    <div className="icon">
                                                        <i className="fa-regular fa-phone-volume"></i>
                                                    </div>
                                                    <div className="information">
                                                        <span style={{ color: "white" }}>+91 79038 29875</span>
                                                        <span className="support">Call us for support</span>
                                                    </div>
                                                </div>

                                                <div className="single-contact-info">
                                                    <div className="icon">
                                                        <i className="fa-regular fa-envelope"></i>
                                                    </div>
                                                    <div className="information">
                                                        <a href="mailto:shekhar.aabasan@gmail.com">shekhar.aabasan@gmail.com</a>
                                                        <span className="support">Email us for query</span>
                                                    </div>
                                                </div>

                                                <div className="single-contact-info">
                                                    <div className="icon">
                                                        <i className="fa-regular fa-map-location-dot"></i>
                                                    </div>
                                                    <div className="information">
                                                        <a href="https://www.google.com/maps" target="_blank">Address</a>
                                                        <span className="support">Prakash Nagar Gururbasa Telco, <br />Jamshedpur - 831004 </span>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="copyright-style-one">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="copyright-area-main-wrapper-one">
                                        <div style={{ display: "flex", flexDirection: "column" }}>
                                            <p className="left">Copyright 2024. All Rights Reserved. S.S Constructions & Services</p>
                                            <p className="left">Developed by <Link target='_blank' to='https://imaginativo.in/'>Imaginativo.</Link> </p>
                                        </div>

                                        {/* <ul>
                                            <li>
                                                <a href="terms-and-condition.html">Terms &amp; conditions</a>
                                            </li>
                                            <li>
                                                <a href="privacy-policy.html">Privacy policy</a>
                                            </li>
                                        </ul> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="search-input-area">
                    <div className="container">
                        <div className="search-input-inner">
                            <div className="input-div">
                                <input id="searchInput1" className="search-input" type="text" placeholder="Search by keyword or #" />
                                <button><i className="far fa-search"></i></button>
                            </div>
                        </div>
                    </div>
                    <div id="close" className="search-close-icon"><i className="far fa-times"></i></div>
                </div>

                <div className="progress-wrap">
                    <svg className="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
                        <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"></path>
                    </svg>
                </div>

                <div id="side-bar" className="side-bar header-two">
                    <button className="close-icon-menu"><i className="far fa-times"></i></button>

                    <div className="inner-main-wrapper-desk">
                        <div className="thumbnail">
                            <img src={logo} alt="Logo" />
                        </div>
                        <div className="inner-content">
                            <p className="disc">
                                S.S CONSTRUCTIONS & SERVICES
                            </p>
                            <div className="contact-information--sidebar">
                                <h6 className="title">Contact Info</h6>
                                <div className="single-info">
                                    <span >+91 7903829875</span>
                                </div>

                                <div className="single-info">
                                    <span >shekhar.aabasan@gmail.com</span>
                                </div>
                                <h6 className="title mt--30">Office Address</h6>
                                <div className="single-info">
                                    <span >Prakash Nagar Gururbasa Telco, <br />Jamshedpur - 831004 </span>
                                </div>

                                <div className="rts-social-style-one">
                                    <ul>
                                        <li>
                                            <a href="#">
                                                <i className="fa-brands fa-facebook-f"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <i className="fa-brands fa-twitter"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <i className="fa-brands fa-youtube"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <i className="fa-brands fa-linkedin-in"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="footer">
                                <h4 className="title">Got a project in mind?</h4>
                                <a target='_blank' href={"https://api.whatsapp.com/send?phone=7903829875"} className="rts-btn btn-primary">Let's talk</a>
                            </div>
                        </div>
                    </div>

                    <div className="mobile-menu-main">
                        <nav className="nav-main mainmenu-nav mt--30">
                            <ul className="mainmenu metismenu" id="mobile-menu-active">
                                <li className="has-droupdown">
                                    <a href={RouteStrings.HomePage} className="main">Home</a>
                                    <ul className="submenu mm-collapse">
                                        <li className="has-droupdown third-lvl">
                                            <a className="main" href="#">Multipages</a>
                                            <ul className="submenu-third-lvl mm-collapse">
                                                <li><a href="index.html"></a>Home One</li>
                                                <li><a href="index-two.html"></a>Home Two</li>
                                                <li><a href="index-three.html"></a>Home Three</li>
                                                <li><a href="index-four.html"></a>Home Four</li>
                                                <li><a href="index-five.html"></a>Home Five</li>
                                                <li><a href="index-six.html"></a>Home Six</li>
                                                <li><a href="index-seven.html"></a>Home Seven</li>
                                            </ul>
                                        </li>
                                        <li className="has-droupdown third-lvl">
                                            <a className="main" href="#">Onepages</a>
                                            <ul className="submenu-third-lvl mm-collapse">
                                                <li><a href="onepage.html"></a>One Page One</li>
                                                <li><a href="onepage-two.html"></a>One Page Two</li>
                                                <li><a href="onepage-three.html"></a>One Page Three</li>
                                                <li><a href="onepage-four.html"></a>One Page Four</li>
                                                <li><a href="onepage-five.html"></a>One Page Five</li>
                                                <li><a href="onepage-six.html"></a>One Page Six</li>
                                                <li><a href="onepage-seven.html"></a>One Page Seven</li>
                                            </ul>
                                        </li>
                                        <li className="has-droupdown third-lvl">
                                            <a className="main" href="#">Showcase Pages</a>
                                            <ul className="submenu-third-lvl mm-collapse">
                                                <li><a href="portfolio-boxes.html"></a>Carousel Slider</li>
                                                <li><a href="varticle-portfolio.html"></a>Vertical Portfolio</li>
                                                <li><a href="varticle-slider.html"></a>Varticl Slider</li>
                                                <li><a href="varticle-slider-tab.html"></a>Vartical Tab Slider</li>
                                                <li><a href="showcase-fade.html"></a>Showcase Fade</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                                <li className="has-droupdown">
                                    <a href={RouteStrings.AboutUs} className="main">About Us</a>
                                    <ul className="submenu mm-collapse">
                                        <li><a className="mobile-menu-link" href="service-1.html">Service One</a></li>
                                        <li><a className="mobile-menu-link" href="service-2.html">Service Two</a></li>
                                        <li><a className="mobile-menu-link" href="service-3.html">Service Three</a></li>
                                        <li><a className="mobile-menu-link" href="service-4.html">Service Four</a></li>
                                        <li><a className="mobile-menu-link" href="service-5.html">Service Five</a></li>
                                        <li><a className="mobile-menu-link" href="service-details.html">Service Details</a></li>
                                    </ul>
                                </li>
                                <li className="has-droupdown">
                                    <a href={RouteStrings.Blogs} className="main">Blog</a>
                                    <ul className="submenu mm-collapse">
                                        <li><a className="mobile-menu-link" href="portfolio-grid-1.html">Portfolio Grid</a></li>
                                        <li><a className="mobile-menu-link" href="portfolio-grid-col-3.html">Portfolio Col-3</a></li>
                                        <li><a className="mobile-menu-link" href="portfolio-grid-col-4.html">Portfolio Col-4</a></li>
                                        <li><a className="mobile-menu-link" href="portfolio-varticle-grid.html">Portfolio Mesonary</a></li>
                                        <li className="has-droupdown third-lvl">
                                            <a className="main" href="#">Project Details</a>
                                            <ul className="submenu-third-lvl mm-collapse">
                                                <li><a href="project-details-1.html"></a>Project Details v.1</li>
                                                <li><a href="project-details-2.html"></a>Project Details v.2</li>
                                                <li><a href="project-details-3.html"></a>Project Details v.3</li>
                                                <li><a href="project-details-4.html"></a>Project Details v.4</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                                <li className="has-droupdown">
                                    <a href={RouteStrings.Gallery} className="main">Gallery</a>
                                    <ul className="submenu mm-collapse">
                                        <li><a className="mobile-menu-link" href="portfolio-grid-1.html">Portfolio Grid</a></li>
                                        <li><a className="mobile-menu-link" href="portfolio-grid-col-3.html">Portfolio Col-3</a></li>
                                        <li><a className="mobile-menu-link" href="portfolio-grid-col-4.html">Portfolio Col-4</a></li>
                                        <li><a className="mobile-menu-link" href="portfolio-varticle-grid.html">Portfolio Mesonary</a></li>
                                        <li className="has-droupdown third-lvl">
                                            <a className="main" href="#">Project Details</a>
                                            <ul className="submenu-third-lvl mm-collapse">
                                                <li><a href="project-details-1.html"></a>Project Details v.1</li>
                                                <li><a href="project-details-2.html"></a>Project Details v.2</li>
                                                <li><a href="project-details-3.html"></a>Project Details v.3</li>
                                                <li><a href="project-details-4.html"></a>Project Details v.4</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                                <li className="has-droupdown">
                                    <a href={RouteStrings.OngoingProjects} className="main">Ongoing Projects</a>
                                    <ul className="submenu mm-collapse">
                                        <li><a className="mobile-menu-link" href="blog.html">Blog</a></li>
                                        <li><a className="mobile-menu-link" href="blog-list.html">Blog List</a></li>
                                        <li><a className="mobile-menu-link" href="blog-2.html">Blog v.2</a></li>
                                        <li><a className="mobile-menu-link" href="blog-3.html">Blog v.3</a></li>
                                        <li><a className="mobile-menu-link" href="blog-4.html">Blog v.4</a></li>
                                        <li><a className="mobile-menu-link" href="blog-details.html">Blog Details</a></li>
                                    </ul>
                                </li>
                                <li className="has-droupdown">
                                    <a href={RouteStrings.UpcomingProjects} className="main">Upcoming Projects</a>
                                    <ul className="submenu mm-collapse">
                                        <li><a className="mobile-menu-link" href="about.html">About</a></li>
                                        <li className="has-droupdown third-lvl">
                                            <a className="main" href="#">Team</a>
                                            <ul className="submenu-third-lvl mm-collapse">
                                                <li><a href="team-1.html"></a>Team One</li>
                                                <li><a href="team-2.html"></a>Team Two</li>
                                                <li><a href="team-details.html"></a>Team Details</li>
                                            </ul>
                                        </li>
                                        <li><a className="mobile-menu-link" href="before-after.html">Before After</a></li>
                                        <li><a className="mobile-menu-link" href="pricing-plane.html">Pricing Plan</a></li>
                                        <li><a className="mobile-menu-link" href="faq.html">Faq's</a></li>
                                        <li><a className="mobile-menu-link" href="terms-and-condition.html">Terms &amp; Conditions</a></li>
                                        <li><a className="mobile-menu-link" href="privacy-policy.html">Privacy Policy</a></li>
                                        <li><a className="mobile-menu-link" href="404.html">404 Page</a></li>
                                    </ul>
                                </li>
                                <li>
                                    <a href={RouteStrings.ContactUs} className="main">Contact Us</a>
                                </li>
                                <div style={{display:"flex",flexDirection:"column",alignItems:"center"}} className="footer">
                                <h4 className="title">Got a project in mind?</h4>
                                <a target='_blank' href={"https://api.whatsapp.com/send?phone=7903829875"} className="rts-btn btn-primary">Let's talk</a>
                            </div>
                            </ul>
                        </nav>

                        <div className="rts-social-style-one pl--20 mt--100">
                            <ul>
                                <li>
                                    <a href="#">
                                        <i className="fa-brands fa-facebook-f"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <FaXTwitter />
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i className="fa-brands fa-youtube"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i className="fa-brands fa-linkedin-in"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>

                </div>



                <div className="grid-line">
                    <div className="grid-lines">
                        <div className="line"></div>
                        <div className="line"></div>
                        <div className="line"></div>
                        <div className="line"></div>
                    </div>
                </div>






                {/* <div className="loader-wrapper">
<div className="loader">
</div>
<div className="loader-section section-left"></div>
<div className="loader-section section-right"></div>
</div>  */}



                <div id="anywhere-home">
                </div>




            </body>
        </>
    )
}

export default Home