import React from 'react';
import logo from './logo.svg';
import './App.css';

import {
  BrowserRouter,
  Routes, // instead of "Switch"
  Route,
} from "react-router-dom";

import { RouteStrings } from "./components/utils/RouteStrings";
import About from './components/About';
import Gallery from './components/Gallery';
import Header from './components/Header';
import CertificateFile from './components/utils/Certificate'

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          {/* <Route path={RouteStrings.HomePage} element={<Home />} /> */}
          <Route path={RouteStrings.HomePage} element={<Header />} />
          <Route path={RouteStrings.AboutUs} element={<Header />} />
          <Route path={RouteStrings.Gallery} element={<Header />} />
          <Route path={RouteStrings.ContactUs} element={<Header />} />
          <Route path={RouteStrings.ContactUs} element={<Header />} />
          <Route path={RouteStrings.Blogs} element={<Header />} />
          <Route path={`${RouteStrings.Blogs}/:no`} element={<Header />} />
          <Route path={RouteStrings.OngoingProjects} element={<Header />} />
          <Route path={RouteStrings.UpcomingProjects} element={<Header />} />
          <Route path="/.well-known/pki-validation/2B0C134DE1D38363CDAFC16FDBC8E673.txt" element={<CertificateFile />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
