import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core';
import { city, messageBox, buildingLong,YoutubeIcon,TwitterIcon,FacebookIcon,InstagramIcon, UrbanImage2, BuildingPanorama, ColorStrings } from './utils/Utils';
import { Collapse, CollapseProps } from 'antd';
import axios from 'axios';
import { BackendRootStrings } from './utils/RouteStrings';
import { BuildingDesignInterface } from './TypeImplements';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

import { FaXTwitter, FaSquareInstagram } from "react-icons/fa6";
import { FaYoutube, FaFacebookSquare } from "react-icons/fa";
const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  '@media (min-width: 780px)': {
    width: '80%'
  },

  box1: {
    backgroundImage: `url(${city})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover"
  },
  mainDiv1: {
    '@media (min-width: 1708px)': {
      height: "3416px !important"
    },
  },
  aboutUsDiv1: {
    '@media (min-width: 1426px)': {
      // marginTop:"-851px !important"
      height: "31% !important"
    },
  },
  aboutUsDiv1div1: {
    '@media (min-width: 1426px)': {
      // marginTop:"-851px !important"
      height: "105% !important"
    },
  },
  boardMemberDiv1: {
    '@media (min-width: 1426px)': {
      // marginTop:-851
      height: "28% !important"
    },
    '@media (max-width: 1426px)': {
      // marginTop:-851
      height: "12% !important",
      marginTop: "548px !important"
    },
  },
  backImage2: {
    backgroundImage: `url("${UrbanImage2}")`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover"
  },
  backImage1: {
    backgroundImage: `url("${BuildingPanorama}")`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover"
  },
  div4: {
    '@media (max-width: 1426px)': {
      // marginTop:-851
      marginTop: "780px !important"
    },
  },
  div5: {
    '@media (max-width: 1426px)': {
      // marginTop:-851
      marginTop: "440px !important"
    },
  },
  iconMainDiv: {
    '@media (max-width: 1426px)': {
      // marginTop:-851
      width: "100% !important"
    },
  },
  backgroundImage3: {
    backgroundImage: `url("${buildingLong}")`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    marginTop:12
  },
  FacebookIcon: {
    backgroundImage: `url("${FacebookIcon}")`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover"
  },
  InstaGramIcon: {
    backgroundImage: `url("${InstagramIcon}")`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover"
  },
  TwitterIcon: {
    backgroundImage: `url("${TwitterIcon}")`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover"
  },
  YoutubeIcon: {
    backgroundImage: `url("${YoutubeIcon}")`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover"
  },


  carauselInsideDiv:{
    '&.ant-collapse .ant-collapse-content>.ant-collapse-content-box':{
      backgroundColor:`${ColorStrings.BlackColor} !important`
    }
  },
  carauselLeftImg:{
    '@media (max-width: 696px)': {
      // marginTop:-851
      width: "440px !important"
    },
  },
  collapseWrap:{
    '@media (max-width: 696px)': {
      marginBottom: "-404px !important"
    },
  },
  AboutUsMainDiv:{
    '@media (max-width: 696px)': {
      zIndex:1,
      marginTop:10
    },
  },
  boardMemberDiv2:{
    '@media (max-width: 696px)': {
      height:"80px !important"
    },
  },
  boardMemberCarauselImg:{
    '@media (max-width: 696px)': {
      height:"200px !important"
    },
  }


}));



const DummyObj = {
  Date: "Date",
  Founder: "Founder Name",
  Chairman: "Chairman Name"
}

const text = `
Lorem ipsum dolor sit amet, consectetur adipisicing elit. Delectus quidem sapiente amet. Excepturi fugit deleniti omnis dicta sapiente consequuntur maiores, ipsa, dolor, autem necessitatibus nostrum eaque nesciunt eveniet sint nulla adipisci unde.
`;

const items: CollapseProps['items'] = [
  {
    key: '1',
    label: <span style={{color:ColorStrings.GoldenColor}}>THE COMPANY</span>,
    children: <p style={{color:ColorStrings.GoldenColor}}>SS Construction & Services, with over 20 years of experience, is revolutionizing the real estate industry in Jamshedpur. We specialize in providing high-quality construction and property management services to our clients, ensuring their satisfaction and trust in our brand.</p>,
  },
  {
    key: '2',
    label: <span style={{color:ColorStrings.GoldenColor}}>MISSION</span>,
    children: <p style={{color:ColorStrings.GoldenColor}}>Our mission at SS Construction & Services is to exceed our clients' expectations by delivering exceptional construction and property management services. We strive to create long-lasting relationships with our clients and partners through our commitment to excellence and integrity.</p>,
  },
  {
    key: '3',
    label: <span style={{color:ColorStrings.GoldenColor}}>VISON</span>,
    children: <p style={{color:ColorStrings.GoldenColor}}>Our vision at SS Construction & Services is to become the leading real estate company in Jamshedpur, known for our innovative solutions, superior quality, and unmatched customer service. We aim to set new standards in the industry and contribute to the growth and development of the community.</p>,
  },
  {
    key:'4',
    label: <span style={{color:ColorStrings.GoldenColor}}>CORE VALUES</span>,
    children:<p style={{color:ColorStrings.GoldenColor,textAlign:'justify'}}>1.	Excellence: We are dedicated to delivering top-notch construction and property management services that meet the highest standards of quality and craftsmanship.
    <br/>2.	Integrity: We conduct our business with honesty, transparency, and ethical practices, earning the trust and respect of our clients and stakeholders.
    <br/>3.	Innovation: We continuously strive to innovate and improve our processes, technologies, and services to stay ahead of the competition and meet the evolving needs of our clients.
    <br/>4.	Customer Satisfaction: We prioritize the satisfaction of our clients and go above and beyond to exceed their expectations, building long-term relationships based on trust and reliability.
    </p>
  }
];



const About = () => {

  const classes = useStyles();

  let [boardMemberDetails, setBoardMemberDetails] = useState<JSX.Element[]>([]);


  useEffect(() => {
    const fetchAboutUsBoardMember = async () => {
      try {
        const { data } = await axios.get(`${BackendRootStrings.apiHome}/getBoardMemberImage`);
        console.log(data);
        let tempArr = [];
        for (let i = 0; i < data.length; i++) {
          tempArr.push(
            <div key={i}>
              <img className={classes.boardMemberCarauselImg} style={{ height: 400, width: 400 }} src={`data:image/png;base64,${data[i].image_url}`} alt={data[i].title} />
              <div style={{ width: "100%", display: "flex",height:70 }}>
                <div style={{ backgroundColor: "black", width: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center",height:"100%" }}>
                  <p style={{ color: "#ca9c6a", position: "relative", top: 15 }}>{data[i].title}</p>
                  <p style={{ color: "white", position: "relative", top: -15 }}>{data[i].subTitle}</p>
                </div>

              </div>

            </div>
          );
        }
        setBoardMemberDetails(tempArr)
      } catch (error) {

      }
    }

    fetchAboutUsBoardMember();
  }, [])


  return (
    <div className={classes.mainDiv1} style={{ display: "flex", justifyContent: "center", height: 4043, }}>
      <div style={{ display: "flex", height: "100%", width: "100%", flexDirection: "column",backgroundColor:ColorStrings.BlackColor }}>
        <div className={classes.backImage1} style={{ width: "100%", height: 400, display: "flex", justifyContent: "center", alignItems: "center" }}>
          {/* <img src={city} style={{ width: "100%", height: "100%" }} alt="" /> */}
          <h2>About US</h2>
        </div>
        <div className={classes.aboutUsDiv1} style={{ width: "100%", display: "flex", height: "21%", justifyContent: "center", flexWrap: "wrap",backgroundColor:ColorStrings.BlackColor }}>
          <div className={classes.aboutUsDiv1div1} style={{ height: "34%", width: 718, display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
            <div style={{ height: "14%", }}>
              <h1 style={{ textAlign: "center",color:'white' }}>ABOUT US</h1>
            </div>
            <div className={classes.box1} style={{ height: "80%", display: "flex", justifyContent: "center" }}>
              <div style={{ height: 150, width: 300 }}>
                <img src={messageBox} alt="" />
                <p style={{ position: "relative", top: -191, left: 42, color: "white",fontSize:30 }}>20+ YEARS OF </p>
                <p style={{ position: "relative", top: -198, left: 46, color: "white",fontSize:30 }}>EXPERIENCE</p>
              </div>
            </div>
          </div>
          <div style={{ height: "100%", width: 718, display: "flex", justifyContent: "center" }}>
            <div className={classes.AboutUsMainDiv} style={{ height: "100%", width: "80%", display: "flex", alignItems: "center", flexDirection: "column" }}>
              <h1 style={{color:ColorStrings.GoldenColor}}>ABOUT US</h1>
              <p style={{color:ColorStrings.GoldenColor,textAlign:'justify'}}>Welcome to S.S CONSTRUCTION & SERVICES , where luxury finds its true expression in every detail.</p>
              <p style={{color:ColorStrings.GoldenColor,textAlign:'justify'}}>Based in Jamshedpur, Jharkhand, we've been a pioneering force in shaping Eastern India's real estate for nearly three decades. With a rich legacy that originated in {DummyObj.Date}, S.S CONSTRUCTION & SERVICES has consistently set industry standards, creating iconic developments that stand as a testament to our dedication to quality and innovation. Through an array of residential, commercial, retail, IT parks, and industrial projects, we strive to be the catalyst propelling you towards elegance and exclusivity.</p>

              <p style={{color:ColorStrings.GoldenColor,textAlign:'justify'}}>At S.S CONSTRUCTION & SERVICES, our unwavering commitment lies in bringing your dreams of a sophisticated lifestyle to fruition. Our team, comprised of industry experts and visionaries, is committed to craftsmanship, integrity, and customer satisfaction.
              </p>
              <p style={{color:ColorStrings.GoldenColor,textAlign:'justify'}}>At the heart of our success lies visionary leadership and an enduring legacy. Guided by Founder {DummyObj.Founder} and Chairman {DummyObj.Chairman}, our leadership is marked by a profound commitment to excellence and a passion for shaping the future of real estate. As we forge ahead, we hold our legacy true to the core, driving innovation and setting benchmarks in the real estate landscape.
              </p>
              
            </div>


          </div>
        </div>
        <div className={classes.boardMemberDiv1} style={{ height: "27%", width: "100%" ,backgroundColor:ColorStrings.BlackColor}}>
          <div className={classes.boardMemberDiv2} style={{ display: "flex", flexDirection: "column", alignItems: "center", height: 220, width: "100%" }}>
            <h3 style={{color:ColorStrings.GoldenColor}}>MEET THE BOARD MEMBER</h3>
            <p>OUR TEAM MEMBERS</p>
          </div>
          <div style={{ display: "flex", justifyContent: "center", height: 520, width: "100%", }}>
            <div style={{ display: "flex", minHeight: "100%", width: "70%", flexDirection: "row", justifyContent: "space-between", alignItems: "center", }}>
              {/* <div style={{ height: "100%", width: 400, border: "1px solid" }}></div> */}
              <AliceCarousel items={boardMemberDetails} responsive={{
                0: { items: 1 },
                568: { items: 2 },
                1024: { items: 3 },
              }} autoPlay autoPlayInterval={2000} disableButtonsControls infinite>

              </AliceCarousel>
            </div>

          </div>
        </div>
        <div className={classes.backgroundImage3} style={{ height: 480, width: "100%", border: "1px solid" }}>

        </div>
        <div className={classes.collapseWrap}  style={{ height: "fit-content !important", width: "100%", display: "flex", flexDirection: "row", justifyContent: "center", flexWrap: "wrap", marginTop: 20,backgroundColor:ColorStrings.BlackColor }}>
          <div style={{ display: "flex", justifyContent: "space-between", width: "80%",flexWrap:"wrap",border:"1px solid" }}>
            <div className={classes.backImage2+" "+classes.carauselLeftImg} style={{ height: 420, width: 720, }}></div>
            <div style={{ height: "100%", width: 720, display: "flex", flexDirection: "column",flexWrap:"wrap" }}>
              <h3 style={{color:ColorStrings.GoldenColor}}>ABOUT SS CONSTRUCTION AND SERVICE</h3>
              <Collapse className={classes.carauselInsideDiv} items={items} defaultActiveKey={['1']} />
            </div>
          </div>

        </div>
        {/* <div className={classes.div4} style={{height:420,width:"80%",display:"flex",flexDirection:"row",justifyContent:"space-between",flexWrap:"wrap",marginTop:20,margin:"auto"}}>
            
            <div className={classes.backImage2} style={{height:"100%",width:721,}}></div>
            <div style={{height:"100%",width:721,}}>
              <h4>CONTACT</h4>
              <p>Lorem ipsum dolor sit amet.</p>
              <p>SS CONSTRUCTION & SERVICES PRAKASH NAGAR,<br/> GURURBASA, TELCO, JAMSEDPUR - 831004</p>
              <p>Phone : 7903829875 <br/>Email : abc@mail.com</p>
            </div>
        </div> */}
        <div className={classes.div5} style={{ height: 100, width: "80%", display: "flex", flexDirection: "row", justifyContent: "space-between", flexWrap: "wrap", margin: "auto",backgroundColor:ColorStrings.BlackColor }}>
          <div style={{ height: "100%", width: 721, display: "flex", alignItems: "center" }}>
            <p>Owned and operated by S.S Construction & Services</p>
          </div>
          <div style={{ height: "100%", width: 721, display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "row" }}>
            <div className={classes.iconMainDiv} style={{ width: "38%",display:"flex",justifyContent:"space-between",height:60,alignItems:"center" }}>
              <div style={{height:42,width:50}} className={classes.FacebookIcon}></div>
              <div style={{height:45,width:45}} className={classes.InstaGramIcon}></div>
              <div style={{height:45,width:52,marginTop:-7}} className={classes.YoutubeIcon}></div>
              <div style={{height:45,width:55,marginTop:-7}} className={classes.TwitterIcon}></div>
              
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}

export default About