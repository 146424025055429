import React, { useEffect, useState } from 'react'
import { BackendRootStrings } from './utils/RouteStrings';
import axios from 'axios';
import { BlogPostsInterface } from './TypeImplements';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { ColorStrings } from './utils/Utils';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    '@media (min-width: 780px)': {
        width: '80%'
    },

    blogPostsClass: {
        '@media (max-width: 500px)': {
            alignItems: "normal !important"
        },
    },
    blogPostsItemsDiv: {
        '@media (max-width: 500px)': {
            alignItems: "center !important",
            justifyContent:"center !important",
        },
    }




}));

const Blog = () => {
    const classes = useStyles();

    const [blogPostsData, setBlogPostsData] = useState<BlogPostsInterface[]>([]);
    const blogPosts = [
        {
            image_url: 'https://via.placeholder.com/1200',
            title: 'Exploring the Beauty of Nature'
        },
        {
            image_url: 'https://via.placeholder.com/1200',
            title: 'The Art of Cooking: A Culinary Journey'
        },
        {
            image_url: 'https://via.placeholder.com/1200',
            title: 'The Impact of Technology on Modern Society'
        },
        {
            image_url: 'https://via.placeholder.com/1200',
            title: 'Unlocking Creativity: Finding Inspiration Everywhere'
        },
        {
            image_url: 'https://via.placeholder.com/1200',
            title: 'Discovering New Horizons: Traveling the World'
        },
        {
            image_url: 'https://via.placeholder.com/1200',
            title: 'The Power of Mindfulness: Living in the Present'
        },
        {
            image_url: 'https://via.placeholder.com/1200',
            title: 'Exploring the Wonders of Space: A Journey Beyond Earth'
        },
        {
            image_url: 'https://via.placeholder.com/1200',
            title: 'The Joy of Reading: Escaping into Fictional Worlds'
        },
        {
            image_url: 'https://via.placeholder.com/1200',
            title: 'Embracing Diversity: Celebrating Differences'
        },
        {
            image_url: 'https://via.placeholder.com/1200',
            title: 'The Pursuit of Happiness: Finding Fulfillment in Life'
        }
    ];

    useEffect(() => {

        const fetchData = async () => {
            try {
                const { data } = await axios.get(`${BackendRootStrings.apiHome}/getBlogData`);
                setBlogPostsData(data);
            } catch (error) {
                console.log(error);
            }

        }

        fetchData();
    }, [])



    return (
        <div style={{ height: "fit-content", width: "100%", display: "flex", justifyContent: "center", backgroundColor: ColorStrings.BlackColor }}>
            <div className={classes.blogPostsClass} style={{ height: "100%", width: "100%", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                <h1 style={{textAlign:"center",color:ColorStrings.GoldenColor,fontWeight:"bold"}}>BLOGS</h1>
                <div style={{ height: 50 }}>

                </div>
                <div className={classes.blogPostsItemsDiv} style={{ height: "100%", width: "100%", display: "flex", flexWrap: "wrap", justifyContent: "flex-start", }}>
                    {
                        blogPostsData.map((obj: BlogPostsInterface, ind: number) => {
                            return (
                                <div  style={{ display: "flex", flexDirection: "column", border: "5px solid", marginTop: 10, justifyContent: "flex-start", alignItems: "flex-start", marginRight: 10 }} key={ind}>
                                    <div><img style={{ height: 250, width: 330 }} src={`data:image/png;base64,${obj.image_url}`} alt="" /></div>
                                    <p style={{ height: "fit-content", width: 330, color: ColorStrings.GoldenColor, fontWeight: "bold", fontSize: 20 }}><Link to={`/blogs/${ind + 1}`} >{obj.title}</Link></p>
                                    <p style={{ height: 100, width: 330, color: "white", }}>{obj.content.substring(0, 40)}</p>
                                </div>
                            )
                        })
                    }
                </div>
                <div style={{ height: 50 }}>

                </div>

            </div>

        </div>
    )
}

export default Blog