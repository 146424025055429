import React, { useState } from 'react';
import '../builders website_innovate theam/css/customContactCss.css';
import { makeStyles } from '@material-ui/core';
import { DatePicker, message } from 'antd';
// import '../builders website_innovate theam/js/customContact'
import type { DatePickerProps } from 'antd';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs'
import { requestVisitInterface,ContactUSInterface } from './TypeImplements';
import { RangePickerProps } from 'antd/es/date-picker';
import axios from 'axios';
import { BackendRootStrings } from './utils/RouteStrings';
import { ColorStrings } from './utils/Utils';
const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    '@media (min-width: 780px)': {
        width: '80%'
    },

    box1: {
        '@media (max-width: 1220px)': {
            display: "flex",
            flexDirection: "column"
        },
    },
    contactForm1: {
        '@media (max-width: 1220px)': {
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
        },
    },
    mainBox1: {
        '@media (max-width: 1220px)': {
            // marginLeft: -108,
            display:"flex",
            justifyContent:"center"
        },
    },
    rightContainer1: {
        '@media (max-width: 1220px)': {
            width: "100% !important",
            display:"flex !important",
            justifyContent:"center !important"
        },
    },
    leftContainer1: {
        '@media (max-width: 1220px)': {
            width: "100% !important",
            display:"flex !important",
            justifyContent:"center !important"
        },
    },
    formDiv1: {
        '@media (max-width: 1220px)': {
            display:"flex",
            justifyContent:"center !important",
            width:"90% !important"
        },
    },
    textLabel1:{
        '@media (max-width: 1220px)': {
            width: "32vw !important"
        },
    },
    antdDateTimePicker:{

        '& .ant-picker-input > input::placeholder': {
            color:"white !important" /* Change the color to your desired color */
        },

        '@media (max-width: 1220px)': {
            // '& .ant-calendar-range' :{
            //     width: '20px !important'
            // },
            // '& .ant-calendar-range-part': {
            //     width: '20px !important'
            // }
            '&.ant-picker-outlined':{
                width:"60% !important"
            },
            
        }
    }





}));


const Contact = () => {

    const classes = useStyles();
    const [loading, setLoading] = useState(false);

    const [requestVisitFormData, setRequestVisitFormData] = useState<requestVisitInterface>({ Date: new Date().toString(), Name: "", Email: "", Message: "" });
    const [contactUSFormData, setContactUSFormData] = useState<ContactUSInterface>({ Name: "", Email: "", Message: "" });

    const handleDateChange = (value: DatePickerProps['value'] | RangePickerProps['value'], dateString: string | string[]) => {
        setRequestVisitFormData((prevState: requestVisitInterface) => {
            return {
                ...prevState,
                Date: new Date(value?.toString() ?? '').toString()
            };
        })
    };

    const handleRequestVisitFormSubmit = async () => {
        let isSubmit = true;
        Object.keys(requestVisitFormData).map((key: string, index: number) => {
            const obj = key as keyof requestVisitInterface;
            if (requestVisitFormData[obj] === "") {
                isSubmit = false;
                message?.error(`${obj} can't be Empty`)
            }
        });
        if (isSubmit) {
            setLoading(true);
            await axios.post(`${BackendRootStrings.apiHome}/visitRequest`, requestVisitFormData);
            message.info(<h1>Saved successfully</h1>);
            setLoading(false);
        }

    }

    const handleContactFormSubmit = async () => {
        let isSubmit = true;
        Object.keys(contactUSFormData).map((key: string, index: number) => {
            const obj = key as keyof ContactUSInterface;
            if (contactUSFormData[obj] === "") {
                isSubmit = false;
                message?.error(`${obj} can't be Empty`)
            }
        });
        if (isSubmit) {
            setLoading(true);
            await axios.post(`${BackendRootStrings.apiHome}/contactUS`, contactUSFormData);
            message.info(<h1>Saved successfully</h1>);
            setLoading(false);
        }
    }



    return (
        <div className='mainBox2' style={{ minHeight: 600, width: "100%", display: "flex", justifyContent: "center", height: "fit-content", }}>

            <div style={{ height: "fit-content", width: "100%", display: "flex", justifyContent: "center",backgroundColor:ColorStrings.BlackColor  }}>

                <div className={classes.mainBox1} style={{ height: "fit-content", width: "95%", display: "flex", flexWrap: "wrap" }}>
                    <div style={{ width: "100%", border: `2px solid ${ColorStrings.GoldenColor}`,height:450 }}>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1300.567297147003!2d86.27487190035224!3d22.781711311115146!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f609ec0580960f%3A0xfb48d785a8e23b4d!2sUrban%20Enclave!5e0!3m2!1sen!2sin!4v1710831970324!5m2!1sen!2sin" width="600" height="446" style={{ border: 0 }} loading="lazy" ></iframe>
                    </div>

                    <div style={{ marginTop: 15, width: "100%",  display: "flex", justifyContent: "space-between",backgroundColor:ColorStrings.BlackColor  }} className={"contact-container" + " " + classes.box1}>
                        <div style={{ display: "flex", alignItems: "center", marginBottom: 20, width: "40%" ,backgroundColor:ColorStrings.BlackColor }} className={"left-col"+" "+classes.leftContainer1}>
                            {/* <img className="logo" src="https://www.indonesia.travel/content/dam/indtravelrevamp/en/logo.png" /> */}
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "60%",backgroundColor:ColorStrings.BlackColor }} className={"right-col" + " " + classes.rightContainer1}>


                            <div className={classes.formDiv1} style={{ display: "flex", flexDirection: "row", width: "111%", justifyContent: "space-between", height: "100%", marginRight: 100,flexWrap:"wrap" }}>
                                <div style={{ width: 500 }}>
                                    <h1 style={{ textAlign: 'left' ,color:ColorStrings.GoldenColor}}>Contact us</h1>
                                    <div className={classes.contactForm1} id="contact-form" style={{width:"80%"}}>
                                        <label style={{color:'white'}} className={'text1'+" "+classes.textLabel1} htmlFor="name">Full name</label>
                                        <input onChange={(e) => {
                                            setContactUSFormData((prevState: ContactUSInterface) => ({
                                                ...prevState,
                                                Name: e.target.value
                                            }));
                                        }} type="text" id="name" name="name" style={{ border: "1px solid",color:'white' }} required />
                                        <label style={{color:'white'}} className={'text1'+" "+classes.textLabel1} htmlFor="email">Email Address</label>
                                        <input onChange={(e) => {
                                            setContactUSFormData((prevState: ContactUSInterface) => ({
                                                ...prevState,
                                                Email: e.target.value
                                            }));
                                        }} type="email" id="email" name="email" style={{ border: "1px solid",color:'white'}} required />
                                        <label style={{color:'white'}} className={'text1'+" "+classes.textLabel1} htmlFor="message">Message</label>
                                        <textarea onChange={(e) => {
                                            setContactUSFormData((prevState: ContactUSInterface) => ({
                                                ...prevState,
                                                Message: e.target.value
                                            }));
                                        }} style={{ border: "1px solid",height:255,backgroundColor:ColorStrings.BlackColor,color:'white'  }} className='text1' color={'black'} rows={6} placeholder="Your Message" id="message" name="message" required></textarea>
                                        <button style={{backgroundColor:ColorStrings.GoldenColor}} disabled={loading} onClick={handleContactFormSubmit} type="submit" id="submit" name="submit">Send</button>
                                    </div>
                                        

                                    
                                </div>
                                <div style={{ width: 500 }}>
                                    <h1 style={{ textAlign: 'left',color:ColorStrings.GoldenColor }}>Request A visit</h1>
                                    <div className={classes.contactForm1} id="contact-form">
                                        <label style={{color:'white'}} className='text1' htmlFor="name1">Full name</label>
                                        <input type="text"  onChange={(e) => {
                                            setRequestVisitFormData((prevState: requestVisitInterface) => ({
                                                ...prevState,
                                                Name: e.target.value
                                            }));
                                        }}
                                            id="name1" name="name" style={{ border: "1px solid",color:'white' }} required />
                                        <label style={{color:'white'}} className='text1' htmlFor="email1">Email Address</label>
                                        <input onChange={(e) => {
                                            setRequestVisitFormData((prevState: requestVisitInterface) => ({
                                                ...prevState,
                                                Email: e.target.value
                                            }));
                                        }} type="email" id="email1" name="email" style={{ border: "1px solid",color:'white' }} required />
                                        <label style={{color:'white'}} className='text1' htmlFor="date">Date</label>
                                        <DatePicker className={classes.antdDateTimePicker} allowClear={false} id='date' style={{backgroundColor:ColorStrings.BlackColor,color:'white' }} onChange={handleDateChange} showTime />
                                        <label style={{color:'white'}} className='text1' htmlFor="message">Message</label>
                                        <textarea onChange={(e) => {
                                            setRequestVisitFormData((prevState: requestVisitInterface) => ({
                                                ...prevState,
                                                Message: e.target.value
                                            }));
                                        }} style={{ border: "1px solid",backgroundColor:ColorStrings.BlackColor }} className='text1' color={'white'} rows={6} placeholder="Your Message" id="message" name="message" required></textarea>
                                        <button style={{backgroundColor:ColorStrings.GoldenColor}} disabled={loading} onClick={handleRequestVisitFormSubmit} id="submit" name="submit">Send</button>

                                    </div>
                                </div>

                            </div>


                            <div id="error"></div>
                            <div id="success-msg"></div>
                        </div>
                    </div>


                </div>
            </div>

        </div>
    )
}

export default Contact