import React, { useState } from 'react';
import { AppstoreOutlined, MailOutlined, SettingOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import { useNavigate } from 'react-router-dom';
import { RouteStrings } from './utils/RouteStrings';
import { ColorStrings } from './utils/Utils';
import { makeStyles } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    '@media (min-width: 780px)': {
        width: '80%'
    },
    label:{
        '@media (max-width: 1605px)': {
            marginLeft: "-22px !important",
            marginRight: "-22px !important",
        },
        '@media (max-width: 1200px)': {
            marginLeft: "-10px !important",
            marginRight: "-10px !important",
        },
    },
    labelColor:{
        '&.ant-menu-light .ant-menu-submenu-selected>.ant-menu-submenu-title':{
            color: "black !important"
        },
        '&.ant-menu-light.ant-menu-horizontal >.ant-menu-submenu::after':{
            display: "none !important"
        },
        '&.ant-menu-light>.ant-menu .ant-menu-item-selected':{
             color: "#7d7d7d !important"
        }
    }

}))

const { SubMenu } = Menu;

const App: React.FC = () => {
    const classes=useStyles();
    const [subMenuOpen, setSubMenuOpen] = useState(false);
    const navigate = useNavigate();

    return (
        <Menu className={classes.labelColor} style={{ backgroundColor: ColorStrings.GoldenColor, padding: 0,color:"black !important"  }} onMouseEnter={() => { setSubMenuOpen(true) }} onMouseLeave={() => { setSubMenuOpen(false) }} openKeys={[(subMenuOpen) ? 'SubMenu' : '']} mode="horizontal">
            <SubMenu
                className={classes.label}
                key="SubMenu"
                title="Projects"
                style={{
                    padding: 0, marginLeft: "-52px",
                    marginRight: "-52px",
                    color:"black !important"
                }}
            >
                <Menu.Item key="setting:1" style={{ backgroundColor: ColorStrings.GoldenColor }} onClick={() => {
                    navigate(RouteStrings.OngoingProjects)
                }}>Ongoing Projects</Menu.Item>
                <Menu.Item key="setting:2" style={{ backgroundColor: ColorStrings.GoldenColor }} onClick={() => {
                    navigate(RouteStrings.UpcomingProjects)
                }}>Upcoming Projects</Menu.Item>
            </SubMenu>
        </Menu>
    );
};

export default App;
