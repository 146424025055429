const RouteStrings={
    HomePage:'/',
    AboutUs:'/aboutUs',
    Gallery:'/gallery',
    ContactUs:'/contactUs',
    Blogs:'/blogs',
    OngoingProjects:'/ongoing-projects',
    UpcomingProjects:'/upcoming-projects',
}
export const BackendRootStrings= {
    // apiHome: "https://builder-back.onrender.com",
    apiHome: "https://ssconstruction-backend.imaginativo.in",
    // apiHome: "http://localhost:5003",
    // apiHome: "http://45.116.191.175:5003",
}
export {RouteStrings};