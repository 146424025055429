import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { BackendRootStrings } from './utils/RouteStrings'
import { useLocation } from 'react-router-dom'
import { BlogPostsInterface } from './TypeImplements'
import { ColorStrings } from './utils/Utils'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  '@media (min-width: 780px)': {
    width: '80%'
  },

  blogPostsImg: {
    '@media (max-width: 600px)': {
      height: "302px !important"
    },
  }



}));

const BlogContent = () => {

  const classes = useStyles();

  const location = useLocation();
  const [data, setData] = useState<BlogPostsInterface>();

  useEffect(() => {
    (async () => {
      const id=location.pathname.split('/')[2];
      const {data}=await axios.get(`${BackendRootStrings.apiHome}/getBlogDataByID/${id}`);
      setData(data[0])
    })()

  }, [])



  return (
    <div style={{ height: "fit-content", width: '100%', display: 'flex', justifyContent: 'center', backgroundColor: ColorStrings.BlackColor, padding: 20 }}>
      <div style={{ height: "fit-content", width: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', marginBottom: 10 }}>
        <h1 style={{ textAlign: 'center', color: ColorStrings.GoldenColor, fontWeight: 'bold', fontSize: 50, }}>{data?.title}</h1>
        <div style={{ display: 'flex', width: '75%', flexDirection: 'column', flexWrap: "wrap", alignItems: "center" }}>
          <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', flexWrap: "wrap" }}>
            <img className={classes.blogPostsImg} style={{ height: 630, width: 820, border: `5px solid ${ColorStrings.GoldenColor}` }} src={`data:image/png;base64,${data?.image_url}`} alt="" />

            <p style={{ textAlign: 'justify', marginLeft: 10, width: 400, color: ColorStrings.GrayWhite }}>{data?.content.substring(0, 1200)}</p>
          </div>
          <div style={{ width: '100%' }}>
            <p style={{ textAlign: 'justify', color: ColorStrings.GrayWhite }}>{data?.content.substring(1200, data?.content.length)}</p>
          </div>

        </div>

        <p></p>
      </div>
    </div>
  )
}

export default BlogContent