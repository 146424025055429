import React from 'react';

function CertificateFile() {
  // You can put the content of your certificate file directly here
  const certificateContent = `
  1605F27C7EC492C94A5355A307331CA58A0EB5002448D7CE5E60209A7922386F
  comodoca.com
  d56835d5a2fcc5c
  `;

  return (
    <div>
      <pre>{certificateContent}</pre>
    </div>
  );
}

export default CertificateFile;
