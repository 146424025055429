
const messageBox=require('./Images/messageBox.webp');
const city=require('./Images/city.webp');
const buildingLong=require('./Images/Building.webp');
const InstagramIcon=require('./Images/Instagram.png');
const FacebookIcon=require('./Images/Facebook.png');
const TwitterIcon=require('./Images/Twitter.png');
const YoutubeIcon=require('./Images/Youtube.png');
const UrbanImage2=require('./Images/2.webp');
const UrbanImage1=require('./Images/1.webp');
const UrbanImage4=require('./Images/4.webp');
const UrbanImage6=require('./Images/6.webp');
const BuildingPanorama=require('./Images/buildingPanoramaMain.webp')
const city1=require('./Images/city1.webp');
const city2=require('./Images/city2.webp');
const city3=require('./Images/city3.webp');
const logo=require('./Images/SSCONSTRUCTION LOGO_F.png');
const dummyProfile=require('./Images/dummy-profile-pic-male1.jpg');

export {messageBox,city,buildingLong,InstagramIcon,FacebookIcon,TwitterIcon,YoutubeIcon,UrbanImage2,BuildingPanorama,UrbanImage1,city1,city2,city3,logo,UrbanImage4,UrbanImage6,dummyProfile};

export const ColorStrings={
    GoldenColor:'#ca9c6a',
    BlackColor:'#292929',
    GrayWhite:'#c7c7d1'
}